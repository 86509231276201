import axios from 'axios';

class Api {
  defaultHeaders () {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    return {
      'x-csrf-token': csrfToken,
      'content-type': 'application/json;charset=UTF-8'
    }
  }
}
export default Api;