// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Api from './Api';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Index from "../components/nps_report/Index";
import ShowNps from "../components/nps_report/ShowNps";
const App = (props) => (
  <Router>
    <Switch>
      <Route
        exact
        path={"/"}
        render={() => {
          return props.params.nps_survey_id ? (
            <ShowNps api={new Api(props)} {...props} />
          ) : (
            <Index api={new Api(props)} {...props} />
          );
        }}
      />
      <Route
        exact
        path={"/dashboards/show_nps"}
        render={() => <ShowNps api={new Api(props)} {...props} />}
      />
    </Switch>
  </Router>
);
export default App;
