import "date-fns";
import React, { useState,useEffect } from "react";
import Nav from "../Nav";
import "../Common.css";
import "./FilterPage.css";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
}));
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function FilterPage(props) {
  const classes = useStyles();
  const ratins = [1,2,3,4,5,6,7,8,9,10];
  const [selectedOption, setSelectedOption] = useState([]);
  let d = new Date();
  let d1 = new Date();
  const [startDate, setStartDate] = useState(new Date(d.setDate(d.getDate()-7)));
  const [endDate, setEndDate] = useState(new Date(d1.setDate(d1.getDate()-1)));
  const [sources, setSources] = useState([])
  const [rating, setRating] = useState({from: 1, to: 10})
  const [isStar, setIsStar] = useState(false)
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  console.log(props.filterData);
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const onSelect = (option)=>{
    setSources(option.map((itm)=>itm.value));
    setSelectedOption(option)
  }
  const resetFilter = async ()=>{
    let d = new Date();
    let date = new Date(d.setDate(d.getDate()-7))
    setIsStar(false);
    setStartDate(date);
    setEndDate(new Date());
    setSources([]);
    setRating({from: 1, to: 10})
    setSelectedOption([])
    console.log(date);
    let options = {
      start_date: date,
      end_date: new Date(),
      booking_source: [],
      rating: '1,10',
      selectedOption: [],
      is_star: false
    }
    props.onClickOpenFilter(false);
    props.handleFilteroptions(options)
    props.setIsFilterApplied(false)
  }

  const applyFilter= ()=>{
    props.onClickOpenFilter(false);
    let options = {start_date: startDate,end_date: endDate,booking_source: sources,rating: '',selectedOption: selectedOption,is_star: isStar}
    let _r =`${rating.from},${rating.to}`
    options.rating=_r;
    console.log(options);
    props.handleFilteroptions(options)
    props.setIsFilterApplied(true)
  };
  
  useEffect(() => {
    let d = new Date();
    let d1 = new Date();
    setStartDate(props.filterData.start_date ? props.filterData.start_date : new Date(d.setDate(d.getDate()-7)));
    setEndDate(props.filterData.end_date ? props.filterData.end_date : new Date(d1.setDate(d1.getDate()-1)));
    setSources(props.filterData.booking_source);
    setRating({from: props.filterData.rating.split(',')[0], to: props.filterData.rating.split(',')[1]})
    setIsStar(props.filterData.is_star)
    setSelectedOption(props.filterData.selectedOption)
    return () => {
      
    }
  }, [])
  return (
    <div>
      <Nav
        {...props}
        title={"Filter Repots"}
        showFilterIcon={false}
        sowSearchIcon={false}
        backPrassArrow={true}
        goBack = {props.onClickOpenFilter}
      ></Nav>

      <div className="row filtrSect">
        <div className="col-12">
          <label className="radWrap">
            <span className="radText fs-0">Show Starred</span>
            <input type="checkbox" checked={isStar} onChange={(e)=>setIsStar(e.target.checked)} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-12">
          <label className="gry75-text fs12">Booking Source</label>
          <Select
          isSearchabl={true}
          value={selectedOption}
          isMulti ={true}
          onChange={(value)=>onSelect(value)}
          options={props.params.booking_sources}
        />  
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-form"
                  label="Start Date"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-to"
                  label="End Date"
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>

        <div className="col-6">
          <label className="gry75-text fs12">Ratings From</label>
          <select className="drop-list" value={parseInt(rating.from)} onChange={(e)=> setRating((prevRating)=>{ 
              return {...prevRating, from: e.target.value}
            })
            }>
            {ratins.map((i)=>
              <option value={i} defaultValue={parseInt(rating.from)} key={`from-${i}`}>{i}</option>
            )}
          </select>
        </div>
        <div className="col-6">
          <label className="gry75-text fs12">Ratings To</label>
          <select className="drop-list" value={parseInt(rating.to)} onChange={(e)=> setRating((prevRating)=> {
            return {...prevRating, to: e.target.value}
            })}>
          {ratins.map((i)=>
              <option value={i} defaultValue={parseInt(rating.to)} key={`to-${i}`}>{i}</option>
          )}
          </select>
        </div>
      </div>
      <div className="row filtBtns">
        <div className="col-6 pt-1 text-center" onClick={()=>resetFilter()}>Clear filter</div>
        <div className="col-6" >
          <button className="btn btn-block" onClick={()=> applyFilter()}>Apply Filter</button>
        </div>
      </div>
      {/* <button className="btn btn-primary" onClick={()=>props.onClickOpenFilter(false)}>Close Filter</button> */}
    </div>
  );
}
