import React from "react";
import "./Common.css";
import "./Nav.css";

export default function Nav(props) {
  
  const doSonthing = (event) => {
    document.getElementById('search_submit').style.zIndex = 20
  };
  return (
    <React.Fragment>
      <div className="row intc-bg">
        <div className="col-12 topNav">
          <div className="col-10 float-left p-0">
          {props.backPrassArrow && (
              <img
                src="https://images.railyatri.in/ry_images_prod/arrow-1602832668.png"
                alt=""
                width="16px"
                className="mr-3"
                onClick={() => props.goBack(false)}
              />
            )}
            <span className="white-text fs-0 fw-500">{props.title}</span>
          </div>
          {props.showFilterIcon && (
            <div className="col-2 float-right p-0">
              {!props.isFilterApplied && (
                <img
                  src="https://images.railyatri.in/ry_images_prod/filter-1618741711.png"
                  className="float-right"
                  width="24px"
                  alt="filter icon"
                  onClick={() => props.onClickOpenFilter(true)}
                />
              )}
              {props.isFilterApplied && (
                <img
                  src="https://images.railyatri.in/ry_images_prod/filteractive-1619418296.png"
                  className="float-right"
                  width="24px"
                  alt="filter icon"
                  onClick={() => props.onClickOpenFilter(true)}
                />
              )}
            </div>
          )}
          <div className="searchBox">
            {/* <img src="https://images.railyatri.in/ry_images_prod/icsearch-1618742109.png" 
            className="" width="" alt="search icon" /> */}

            {props.sowSearchIcon && (
              <div id="wrap">
                <form onSubmit={props.getNpsByID}>
                  <input
                    id="search"
                    name="search"
                    value={props.surveyID}
                    type="text"
                    placeholder="Search By Id..."
                    onFocus ={doSonthing}
                    onChange={(e) => props.handleSearchById(e.target.value)}
                  />
                  <input id="search_submit" value="Submit" type="submit" />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
