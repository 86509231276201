import React, { useState, useEffect, useCallback } from "react";
import Nav from "../Nav";
import AutocompleteList from "./AutocompleteList";
import FilterPage from "./FilterPage";
import ListCard from "./ListCard";
import RatingBox from "./RatingBox";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dWith: {
    width: '20.5rem',
  },
  cardPad:{
    marginTop: 10,
    marginBottom: 10
  }
});
export default function ShowNps(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [currentService, setCurrentService] = useState(props.params.services[0]);
  const [dayWiseNps, setDayWiseNps] = useState([]);
  const [openfilter, setOpenfilter] = useState(false);
  const [surveyID, setSurveyID] = useState((props.params.nps_survey_id ? props.params.nps_survey_id : null))
  const [averageRating, setAverageRating] = useState({
    rating: 0.0,
    nps: 0,
    total_survey: 0,
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const fetchMyAPI = useCallback(async () => {
    let url = `/dashboards/get_survey_by_id?nps_survey_id=${props.params.nps_survey_id}`
    let response = await fetch(url);
    response = await response.json();
    setIsLoading(false);
    if (response.success) {
      setDayWiseNps(response.data);
      setCurrentService({value: response.service_id, label: response.service_name})
      setAverageRating(() => {
        return {
          nps: response.nps,
          rating: response.avg_rating,
          total_survey: response.surveys_found,
        };
      });
    }
    console.log(response);
  }, [surveyID]);

  const onClickOpenFilter = (open) => {
    setOpenfilter(open);
  };

  const handleChange = (service) => {
    setCurrentService(service);
  };
  const goBack = (open)=>{
    // history.back()
    if (history.state==null){
      debugger
      history.pushState({},'','/')
      history.push('/')
    }
    else{
      history.back()
    }
  }
  useEffect(() => {
    setIsLoading(true);
    let Id = location.search.split('=')[1];
    setSurveyID(Id)
    if(surveyID || Id){
      setCurrentService({})
      fetchMyAPI();
    }
    return () => {
      
    }
  }, [fetchMyAPI])

  return (
    <React.Fragment>
      <div className="container-fluid">
        {!openfilter && (
          <div className="MainPage">
            <div className="nps-report">
              <Nav
                {...props}
                title={"NPS Report"}
                showFilterIcon={false}
                sowSearchIcon={false}
                onClickOpenFilter={onClickOpenFilter}
                isFilterApplied = {isFilterApplied}
                backPrassArrow={true}
                goBack = {goBack}
                surveyID = {surveyID}
              ></Nav>
              {/* <div className="row intc-bg">
                <AutocompleteList
                  auto_complete_data={props.params.services}
                  handleChange={handleChange}
                  currentService = {currentService}
                ></AutocompleteList>
              </div> */}
            </div>
            <div className="">
              <RatingBox
                name={`${currentService.value}-${currentService.label}`}
                averageRating={averageRating}
                boxDisplay={false}
              />
            </div>
            {isLoading && (
              <div className="MainPage">
                {[0, 2].map((i) => (
                  <Card key={`skelton-${i}`} className={classes.cardPad}>
                    <CardContent>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
            <ListCard dayWiseNps={dayWiseNps}></ListCard>
          </div>
        )}
        {openfilter && (
          <div className="FilterPage">
            <FilterPage
              {...props}
              onClickOpenFilter={onClickOpenFilter}
              handleFilteroptions={handleFilteroptions}
              filterData = {filterData}
              setIsFilterApplied = {setIsFilterApplied}
            ></FilterPage>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
