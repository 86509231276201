/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import "../Common.css";
import "./AutocompleteList.css";
import Select from "react-select";

export default function AutocompleteList({
  auto_complete_data,
  handleChange,
  currentService
}) {
  const [selectedOption, setSelectedOption] = useState(currentService);
  const onSelect = (option) => {
    handleChange(option);
    setSelectedOption(option);
  };
  return (
    <React.Fragment>
      <div className="col-12">
        <div className="pad-bt-tp-15">
          <Select
            isSearchabl={true}
            defaultValue={selectedOption}
            onChange={(value) => onSelect(value)}

            options={auto_complete_data}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
