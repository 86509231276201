import React, { useState, useEffect, useCallback } from "react";
import Nav from "../Nav";
import AutocompleteList from "./AutocompleteList";
import FilterPage from "./FilterPage";
import ListCard from "./ListCard";
import RatingBox from "./RatingBox";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  dWith: {
    width: '20.5rem',
  },
  cardPad:{
    marginTop: 10,
    marginBottom: 10
  }
});
export default function Index(props) {
  let history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [currentService, setCurrentService] = useState(props.params.services[0]);
  const [dayWiseNps, setDayWiseNps] = useState([]);
  const [surveys, setSurveys] = useState({});
  const [stars, setStars] = useState([])
  const [filterData, setFilterData] = useState({start_date: null,end_date: null,booking_source: null,rating: "1,10", selectedOption: null,is_star: false});
  const [openfilter, setOpenfilter] = useState(false);
  const [surveyID, setSurveyID] = useState('')
  const [averageRating, setAverageRating] = useState({
    rating: 0.0,
    nps: 0,
    total_survey: 0,
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const fetchMyAPI = useCallback(async (options={start_date: null,end_date: null,booking_source: null,rating: "",is_star: false}) => {
    let queryParams = Object.entries(options)
      .map((v, i) =>v.join("=")).join("&");
    let response = await fetch(
      `/dashboards/nps_report?service_id=${currentService.value}&${queryParams}`
    );
    response = await response.json();
    setIsLoading(false);
    if (response.success) {
      setSurveys(response);
      setDayWiseNps(response.data);
      setStars(response.survey_stars)
      setAverageRating(() => {
        return {
          nps: response.nps,
          rating: response.avg_rating,
          total_survey: response.surveys_found,
        };
      });
    }
    console.log(response);
  }, [currentService.value]);
  const starMarkUpdat = (isMarked,id)=>{
    if(isMarked){
      setStars([...stars,id])
    }else{
      setStars(stars.filter(item => item!==id))
    }
  }
  const onClickOpenFilter = (open) => {
    setOpenfilter(open);
  };

  const handleChange = (service) => {
    setCurrentService(service);
  };

  const handleFilteroptions = (options) => {
    setIsLoading(true);
    setFilterData(prevFilterData =>({ ...prevFilterData, ...options }));
    if (currentService.value) {
      fetchMyAPI(options);
    }
  };
  
  const getNpsByID = (event)=>{
    console.log(surveyID);
    event.preventDefault();
    document.getElementById('search_submit').style.zIndex = 2
    if(surveyID){
    history.push(`/dashboards/show_nps?nps_survey_id=${surveyID}`)
    }
  }
  const handleSearchById = (value)=>{
    setSurveyID(value)
  }
  useEffect(() => {
    setIsLoading(true);
    if (currentService.value) {
      fetchMyAPI(filterData);
    }

    return () => {};
  }, [fetchMyAPI]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        {!openfilter && (
          <div className="MainPage">
            <div className="nps-report">
              <Nav
                {...props}
                title={"NPS Report"}
                showFilterIcon={true}
                sowSearchIcon={true}
                onClickOpenFilter={onClickOpenFilter}
                isFilterApplied = {isFilterApplied}
                getNpsByID = {getNpsByID}
                backPrassArrow={false}
                surveyID = {surveyID}
                handleSearchById = {handleSearchById}
              ></Nav>
              <div className="row intc-bg">
                <AutocompleteList
                  auto_complete_data={props.params.services}
                  handleChange={handleChange}
                  currentService = {currentService}
                ></AutocompleteList>
              </div>
            </div>
            <div className="">
              <RatingBox
                name={currentService.label}
                averageRating={averageRating}
                boxDisplay={true}
              />
            </div>
            {isLoading && (
              <div className="MainPage">
                {[0, 2, 3, 4, 5, 6].map((i) => (
                  <Card key={`skelton-${i}`} className={classes.cardPad}>
                    <CardContent>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }} >
                        <Skeleton variant="rect"  />
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
            <ListCard dayWiseNps={dayWiseNps} api = {props.api} starMarkUpdat={starMarkUpdat} stars = {stars}></ListCard>
          </div>
        )}
        {openfilter && (
          <div className="FilterPage">
            <FilterPage
              {...props}
              onClickOpenFilter={onClickOpenFilter}
              handleFilteroptions={handleFilteroptions}
              filterData = {filterData}
              setIsFilterApplied = {setIsFilterApplied}
            ></FilterPage>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
