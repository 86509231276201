import React, { useState } from "react";
import "../Common.css";
import "./ListCard.css";
import InformationModal from "./InformationModal";
import StarRateSharpIcon from '@material-ui/icons/StarRateSharp';
import axios from "axios";
export default function ListCard({ dayWiseNps, api,starMarkUpdat,stars}) {
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [surveyData, setSurveyData] = useState({})
  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  };
  const markStar = (req)=>{
    let payload = {pnr: req.pnr,nps_survey_data_id: req.id}
    axios.post('/dashboards/set_nps_survey_star',payload, config).then((res)=>{
      console.log(res);
      if(res.data.success){
        starMarkUpdat(res.data.survey_star.is_marked,res.data.survey_star.nps_survey_data_id)
      }
    })
  }
  return (
    <React.Fragment>
      <div className="row pb-5">
        {/* No NPS data */}
        {/* <div className="listCard_title">
          <div className="col-12">
            <p className="m-0 fs-0">Sun, Apr 04, 2021</p>
          </div>
        </div>
        <div className="col-12 text-center listCard_noNPS">
          <em className="fs-0">
            No NPS data yet
          </em>
        </div> */}
        {/* No NPS data/ */}

        {/* NPS data available */}
        {dayWiseNps.map((survey) => (
          <div className="col-12" key={survey.trip_survey.bus_number+survey.date}>
            <div className="listCard_title">
              <div className="col-12">
                <p className="m-0 fs-0">{survey.date}</p>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row">
                {survey.trip_survey.surveys_found===0 && (
                  <div className="col-12 text-center listCard_noNPS">
                    <em className="fs-0">{survey.trip_survey.bus_run ? 'No NPS data yet' : 'No service run'} </em>
                  </div>
                )}
            </div>    
            {(survey.trip_survey.surveys_data || []).map((_data) => (
              <div className="row" key={_data.id}>
                  <div className="col-12 listCard_rating">
                    {_data.rating <= 4 && (
                      <div className="ratingCnt fw-500 fs-1 rtngOrg">
                        <span>{_data.rating}</span>
                      </div>
                    )}
                    {_data.rating > 4 && _data.rating <= 7 && (
                      <div className="ratingCnt fw-500 fs-1 rtngYlw">
                        <span>{_data.rating}</span>
                      </div>
                    )}
                    {_data.rating > 7 && _data.rating <= 10 && (
                      <div className="ratingCnt fw-500 fs-1 rtngGrn">
                        <span>{_data.rating}</span>
                      </div>
                    )}

                    {stars.includes(_data.id) ? <img
                      src="https://images.railyatri.in/ry_images_prod/star-active-1618855223.png"
                      alt="active star icon"
                      className="starLabel"
                      onClick = {()=> markStar(_data)}
                    /> :  <img
                      src="https://images.railyatri.in/ry_images_prod/star-unactive-1583302047.png"
                      alt="inactive star icon"
                      className="starLabel"
                      width="22"
                      style={{height: 20,width: 18, top: 20}}
                      height="22"
                      onClick = {()=> markStar(_data)}
                    />}
                    
                    {_data.resolution_comment.length===0 && <img
                      src="https://images.railyatri.in/ry_images_prod/idis-1618856051.png"
                      alt="I disabled icon"
                      className="infoLabel_dis"
                      data-toggle="modal"
                      data-target="#infoModal"
                      onClick={()=>{
                        setCurrentSurvey(survey.trip_survey)
                        setSurveyData(_data)
                      }}
                    />}

                    {_data.resolution_comment.length>0 && <img
                      src="https://images.railyatri.in/ry_images_prod/iact-1618856046.png"
                      alt="disabed I icon"
                      className="infoLabel_act"
                      data-toggle="modal"
                      data-target="#infoModal"
                      onClick={()=>{
                        setCurrentSurvey(survey.trip_survey)
                        setSurveyData(_data)
                      }}
                    />}
                    <div className="listCard_block">
                      <p className="mb-1 fs-1 black-text fw-500 w-75 listCard_head">
                        {_data.improve_services
                          ? (_data.improve_services.split(',')[0].length>0 ? _data.improve_services.split(',')[0] : "")
                          : ""}
                      </p>
                      <p className="mb-1 fs-0 black-text font-italic w-75 listCard_summ">
                        '
                        {_data.about_exprience
                          ? _data.about_exprience
                          : ""}
                        '
                      </p>
                      {/* <p className="m-0 fs-0 black-text font-italic w-75 listCard_full">
                        'Boarding crew was talking very rudely when asked to
                        handle my luggage with care'
                      </p> */}
                    </div>
                  </div>
                <div className="clearfix"></div>
              </div>
            ))}
          </div>
        ))}
        <InformationModal survey={surveyData} busDetails={currentSurvey} api = {api} markStar = {markStar} stars  = {stars}></InformationModal>
      </div>
    </React.Fragment>
  );
}
